const holes = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17s",
  "17w",
  "18s",
  "18w",
  "19",
  "20",
]

const holeInfo = [
  { holeNum: "1", teepad: "Regular", par: 3, distance: "355 ft", sponsor: 'Eric J. Mowry', title: 'Grapes of Wrath' },
  { holeNum: "1", teepad: "Short", par: 3, distance: "267 ft", sponsor: 'Eric J. Mowry', title: 'Grapes of Wrath' },
  { holeNum: "2", teepad: "Regular", par: 3, distance: "365 ft", sponsor: 'Nick Terralavoro', title: 'Outside the Pines' },
  { holeNum: "3", teepad: "Summer", par: 3, distance: "267 ft", sponsor: 'Daniel "Cookie" Burnette', title: 'Tunnel Vision' },
  { holeNum: "3", teepad: "Winter", par: 3, distance: "287 ft", sponsor: 'Daniel "Cookie" Burnette', title: 'Tunnel Vision' },
  { holeNum: "3", teepad: "Long", par: 3, distance: "313 ft", sponsor: 'Daniel "Cookie" Burnette', title: 'Tunnel Vision' },
  { holeNum: "4", teepad: "Regular", par: 3, distance: "205 ft", sponsor: 'Freeman Woodworks', title: 'The Haunted Camp' },
  { holeNum: "4", teepad: "App", par: 3, distance: "203 ft", sponsor: 'Freeman Woodworks', title: 'The Haunted Camp' },
  { holeNum: "5", teepad: "Regular", par: 3, distance: "287 ft", sponsor: 'Craig Henniger', title: 'Mini Mt Beacon' },
  { holeNum: "5", teepad: "Short", par: 3, distance: "271 ft", sponsor: 'Craig Henniger', title: 'Mini Mt Beacon' },
  { holeNum: "6", teepad: "A", par: 3, distance: "240 ft", sponsor: 'Will Byrne', title: 'A Walk in the Woods' },
  { holeNum: "6", teepad: "B", par: 3, distance: "220 ft", sponsor: 'Will Byrne', title: 'A Walk in the Woods' },
  { holeNum: "6", teepad: "Long", par: 4, distance: "400 ft", sponsor: 'Will Byrne', title: 'A Walk in the Woods' },
  { holeNum: "7", teepad: "Regular", par: 3, distance: "248 ft", sponsor: 'Brent Baisley', title: 'Watertank Down' },
  { holeNum: "8", teepad: "Regular", par: 3, distance: "225 ft", sponsor: 'David Kimmelman', title: 'The Trailhead' },
  { holeNum: "9", teepad: "Regular", par: 3, distance: "233 ft", sponsor: 'Christian Blaney', title: 'R.I.P. 3 Stooges' },
  { holeNum: "10", teepad: "Regular", par: 3, distance: "185 ft", sponsor: 'Gary Mason', title: 'Forget Yew' },
  { holeNum: "11", teepad: "Regular", par: 3, distance: "227 ft", sponsor: 'Mike Bruni', title: 'Front & Center' },
  { holeNum: "12", teepad: "Regular", par: 3, distance: "322 ft", sponsor: 'Ryan Nelson', title: 'Stones Throw' },
  { holeNum: "13", teepad: "Regular", par: 3, distance: "327 ft", sponsor: 'Seath Hurbanek', title: 'Drop Zone' },
  { holeNum: "14", teepad: "Regular", par: 3, distance: "227 ft", sponsor: '', title: 'Dogleg to Nowhere' },
  { holeNum: "14", teepad: "Long", par: 3, distance: "327 ft", sponsor: '', title: 'Dogleg to Nowhere' },
  { holeNum: "15", teepad: "Regular", par: 3, distance: "327 ft", sponsor: 'Mazmd', title: 'Heart Attack and Vine' },
  { holeNum: "16", teepad: "Regular", par: 3, distance: "248 ft", sponsor: 'Griffin Peets', title: 'Down to the Glades' },
  { holeNum: "17s", teepad: "Summer", par: 3, distance: "240 ft", sponsor: '', title: 'Shut Up & Sweet Up' },
  { holeNum: "17w", teepad: "Winter", par: 3, distance: "232 ft", sponsor: '', title: 'Thru the Osage' },
  { holeNum: "18s", teepad: "Summer", par: 3, distance: "200 ft", sponsor: '', title: 'Yeah, Good Luck' },
  { holeNum: "18w", teepad: "Winter", par: 3, distance: "232 ft", sponsor: 'Wedge', title: 'The Pool Hole' },
  { holeNum: "19", teepad: "Summer", par: 3, distance: "233 ft", sponsor: '', title: 'The Mole Hole' },
  { holeNum: "19", teepad: "Winter", par: 4, distance: "314 ft", sponsor: '', title: 'The Mole Hole' },
  { holeNum: "20", teepad: "Regular", par: 4, distance: "240 ft", sponsor: 'Christopher Del Bianco', title: 'In the Pines' },
]

export { holes, holeInfo }
